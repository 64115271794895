import React from 'react';
import Layout from '../../../components/layouts';
import SEO from '../../../components/common/SEO';
import './joke-of-the-week.scss';
import Banner from '../../../components/features/Banner'
import { graphql } from 'gatsby';
import SignUpBtn from '../../../components/features/SignUpBtn'
import Badge from '../../../components/badge/badge';
import ReactWOW from 'react-wow';

export const query = graphql`
  query jokeOfTheWeek {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    features: prismic {
        allFeatures {
            edges{
                node {
                    meta_title
                    meta_description
                    social_title
                    social_description
                    banner_title
                    banner_subtitle
                    banner_feature_name
                    banner_right_button_text
                    banner_right_button_source
                    question_text
                    question_answer
                    question_bullets {
                        bullet_text
                    }
                    question_image
                    video_source {
                        _linkType
                        __typename
                    }
                    video_statement {
                        text
                    }
                    information_list {
                        title
                        subtitle
                    }
                    steps_title
                    steps_subtitle
                    step_list {
                        image_source
                        title
                        subtitle
                    }
                    
                }
            }
        }
    }
  }
`

 export default function JokeOfTheWeek({data}){
    const features = data.features.allFeatures;
    const seo = {
        title: 'Workhub | Joke of the week',
        description: 'Meet minimum legislative or certification requirements by having a fun, weekly incentive. Unlocking the weekly joke can be fulfilled by completing a minimum amount of compliance items to help incentivize.',
        image: '/features/joke-of-the-week/hero.png',
    }

    const bannerProps = {
        title: 'Joke of the Week',
        featureName: 'Joke of the week',
        mockupImg: '/features/joke-of-the-week/hero.png'
    }

    return (
        <Layout>
            <SEO {...seo} {...data.site}/>
            <Banner {...bannerProps}/>
            <main className="feature-main">
                <section className="feature-section joke_header">
                    <div className='joke_feature_header'>
                        <h1>Safety First, Laughs Second</h1>
                        <subtitle>A weekly reward for keeping up with your safety requirements.</subtitle>
                    </div>
                    <div className='feature-info'>
                        <p>Having a small, regularly-occurring incentive as part of your organization’s safety program can help your workforce progress forward and help you meet legislative/certification requirements. </p>
                        <p>Joke of the Week can help you meet this need by allowing you to choose how many compliance items per week and what percent compliance is required to unlock the joke. Jokes are light-hearted fun and are new each week. Think it’s hilarious? Give it a thumbs up and watch the comedian soak in the applause. Not quite your style? Share your reaction and see how the show takes a twist - anything can happen, from a laugh track to a surprise splash of rotten tomatoes!</p>
                    </div>
                    <div className='joke_feature_points'>
                        <div className='joke_feature_list'>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Customizable:</strong> </p>
                            <div>
                                <p>Joke of the Week has several settings that you can tune to match your organization’s current onboarding journey. You can increase the amount of items needed to be completed to help accelerate onboarding, or increase the compliance percentage required to encourage workers to stay compliant.</p>
                            </div>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Set and Forget:</strong> </p>
                            <p>Including Joke of the Week in your company’s culture and recognition program can help show a lighter, fun side of your organization, increasing perception and helping increase morale.</p>

                        </div>
                    </div>
                </section>
                <section className='feature-section'>
                    <SignUpBtn/>
                </section>

                <section className='feature-section'>
                    <div className='joke_feature_options'>
                    <ReactWOW animation="fadeInLeft" delay="0.1s">
                        <div className='joke_images_overlap'>
                            <img src="/features/joke-of-the-week/account-settings-template.png" alt="joke of the week program page" />
                        </div>
                    </ReactWOW>
                        <div>
                            <h1>Variety is the Spice of Life</h1>
                            <p>
                                Joke of the Week has new content every week and is sure to put a smile on your face. Help encourage your workforce to log into Workhub weekly and complete your onboarding program. 
                                <br />
                                <br />
                                Help encourage your workforce to push to complete that one extra policy or do one more lesson by having a Joke of the Week available. It’s the perfect cherry on top!
                            </p>
                        </div>
                    </div>

                </section>
                <section className='feature-section steps' id="overview-cards"> 
                    <h1>How it Works</h1>
                    <div className='joke_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="1" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div className='_option'>
                                <p className='step-header'>Setup Your Program</p>
                                <p>Choose how many compliance items and what compliance percentage a worker needs to view the joke to match your organization’s current needs.</p>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className='joke_how_it_works'>
                    <ReactWOW animation="fadeInLeft" delay="0.2s">
                        <div className='_option'>
                            <p className='step-header'>Let Your Company Culture Grow</p>
                            <p>Once workers have met the requirements, they can view the joke of the week and choose a reaction to the joke.</p>
                        </div>
                    </ReactWOW>

                        <Badge className="badge" content="2" size="large"/>
                        <div className='empty'></div>
                    </div>

                    <div className='joke_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="3" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.3s">
                            <div className='_option'>
                                <p className='step-header'>See the Results</p>
                                <p>See your compliance percent increase as workers complete more onboarding items.</p>
                            </div>
                        </ReactWOW>

                    </div>

                </section>
            </main>
        </Layout>
    )
 }