import React from "react";
import './badge.scss';

const Badge = ({content, size = "medium"}) => {
    return (
        <div className="badge" data-size={size}>
            {content}
        </div>
    )
}

export default Badge;